import React, { useState } from "react";
import Modal from "react-modal";
import Brand from "./Brand";

Modal.setAppElement("#root");

const About = () => {
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <div className="tokyo_tm_about">
        <div className="about_image ">
          <img src="assets/img/slider/1.jpg" alt="about" />
        </div>
        {/* <div className="o-video">
          <iframe src="https://www.youtube.com/embed/0yW7w8F2TVA"></iframe>
        </div> */}
        {/* END ABOUT IMAGE */}
        <div className="description">
          <h3 className="name">名城 大佑</h3>
          <div className="description_inner">
            <div className="left">
              <p>
                大学を卒業後、事業会社勤務を経て2014年にRuby on Railsをメインとする開発会社を起業。WEBアプリケーション開発からクラウドサーバー構築、スマートフォンアプリケーション開発まで幅広く業務を経験してきました。
              </p>
              <p>
                初めて触れたパソコンはApple ComputerのMacintosh Color Classic II。ハード・ソフト共にとても美しいデザインだと感動し、以来Apple一筋です。
              </p>
            </div>
            {/* END LEFT */}
            <div className="right">
              <ul>
                <li>
                  <p>
                    <span>所属:</span>ダブルメガネ株式会社
                  </p>
                </li>
                <li>
                  <p>
                    <span>生年月日:</span>1982/03/29
                  </p>
                </li>
                <li>
                  <p>
                    <span>住所:</span>東京都三鷹市
                  </p>
                </li>
                <li>
                  <p>
                    <span>出身地:</span>沖縄県
                  </p>
                </li>
                <li>
                  <p>
                    <span>出身校:</span>国際基督教大学
                  </p>
                </li>                <li>
                  <p>
                    <span>趣味:</span>音楽鑑賞/ 美術鑑賞 / ハイキング
                  </p>
                </li>
              </ul>
              {/* END UL */}
            </div>
            {/* END RIGHT */}
          </div>
          {/* END DESCRIPTION INNER */}
        </div>

        <div className="description">
          <div className="description_inner">
            <div className="left">
              <div className="about_title">
                <h3>サーバサイド</h3>
              </div>
              {/* END ABOUT TITLE */}

              <div className="tokyo_progress">
                <div className="progress_inner" data-value="90">
                  <span>
                    <span className="label">Ruby</span>
                    <span className="number">90%</span>
                  </span>
                  <div className="background">
                    <div className="bar">
                      <div
                        className="bar_in"
                        style={{ width: 90 + "%" }}
                      ></div>
                    </div>
                  </div>
                </div>

                <div className="progress_inner" data-value="80">
                  <span>
                    <span className="label">Ruby on Rails</span>
                    <span className="number">80%</span>
                  </span>
                  <div className="background">
                    <div className="bar">
                      <div
                        className="bar_in"
                        style={{ width: 80 + "%" }}
                      ></div>
                    </div>
                  </div>
                </div>

                <div className="progress_inner" data-value="70">
                  <span>
                    <span className="label">Python</span>
                    <span className="number">70%</span>
                  </span>
                  <div className="background">
                    <div className="bar">
                      <div
                        className="bar_in"
                        style={{ width: 70 + "%" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              {/* END PROGRESS */}
            </div>
            {/* END LEFT */}

            <div className="right">
              <div className="about_title">
                <h3>フロントエンド</h3>
              </div>
              {/* END TITLE */}
              <div className="tokyo_progress">
                <div className="progress_inner" data-value="65">
                  <span>
                    <span className="label">React.js</span>
                    <span className="number">65%</span>
                  </span>
                  <div className="background">
                    <div className="bar">
                      <div
                        className="bar_in"
                        style={{ width: 65 + "%" }}
                      ></div>
                    </div>
                  </div>
                </div>

                <div className="progress_inner" data-value="70">
                  <span>
                    <span className="label">JavaScript</span>
                    <span className="number">70%</span>
                  </span>
                  <div className="background">
                    <div className="bar">
                      <div
                        className="bar_in"
                        style={{ width: 70 + "%" }}
                      ></div>
                    </div>
                  </div>
                </div>

                <div className="progress_inner" data-value="55">
                  <span>
                    <span className="label">Next.js</span>
                    <span className="number">55%</span>
                  </span>
                  <div className="background">
                    <div className="bar">
                      <div
                        className="bar_in"
                        style={{ width: 55 + "%" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              {/* EDN TOKYO PROGRESS */}
            </div>
            {/* END RIGHT */}
          </div>
          {/* END DESCRIPTION INNER */}
        </div>

        <div className="description">
          <div className="description_inner">
            <div className="left">
              <div className="about_title">
                <h3>クラウドサーバー</h3>
              </div>
              {/* END ABOUT TITLE */}

              <div className="tokyo_progress">
                <div className="progress_inner" data-value="75">
                  <span>
                    <span className="label">Amazon Web Service（AWS）</span>
                    <span className="number">75%</span>
                  </span>
                  <div className="background">
                    <div className="bar">
                      <div
                        className="bar_in"
                        style={{ width: 75 + "%" }}
                      ></div>
                    </div>
                  </div>
                </div>

                <div className="progress_inner" data-value="50">
                  <span>
                    <span className="label">Google Cloud Platform（GCP）</span>
                    <span className="number">50%</span>
                  </span>
                  <div className="background">
                    <div className="bar">
                      <div
                        className="bar_in"
                        style={{ width: 50 + "%" }}
                      ></div>
                    </div>
                  </div>
                </div>

                <div className="progress_inner" data-value="65">
                  <span>
                    <span className="label">Heroku</span>
                    <span className="number">65%</span>
                  </span>
                  <div className="background">
                    <div className="bar">
                      <div
                        className="bar_in"
                        style={{ width: 65 + "%" }}
                      ></div>
                    </div>
                  </div>
                </div>

              </div>
              {/* END PROGRESS */}
            </div>
            {/* END LEFT */}

            <div className="right">
              <div className="about_title">
                <h3>ミドルウェア・その他</h3>
              </div>
              {/* END TITLE */}
              <div className="tokyo_progress">
                <div className="progress_inner" data-value="80">
                  <span>
                    <span className="label">PostgreSQL</span>
                    <span className="number">80%</span>
                  </span>
                  <div className="background">
                    <div className="bar">
                      <div
                        className="bar_in"
                        style={{ width: 80 + "%" }}
                      ></div>
                    </div>
                  </div>
                </div>

                <div className="progress_inner" data-value="70">
                  <span>
                    <span className="label">Linux</span>
                    <span className="number">70%</span>
                  </span>
                  <div className="background">
                    <div className="bar">
                      <div
                        className="bar_in"
                        style={{ width: 70 + "%" }}
                      ></div>
                    </div>
                  </div>
                </div>

                <div className="progress_inner" data-value="65">
                  <span>
                    <span className="label">Docker</span>
                    <span className="number">65%</span>
                  </span>
                  <div className="background">
                    <div className="bar">
                      <div
                        className="bar_in"
                        style={{ width: 65 + "%" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              {/* EDN TOKYO PROGRESS */}
            </div>
            {/* END RIGHT */}
          </div>

          <div className="counter">
            <div className="about_title">
              <h3>保有資格</h3>
            </div>
            <ul>
              <li>
                <div className="list_inner">
                  <h3><img src="https://www.ruby.or.jp/assets/images/ja/certification/examination/logo_gold_v21.png" alt="ruby gold" /></h3>
                  <span>Ruby Programmer 2.1<br />Gold</span>
                </div>
              </li>
              <li>
                <div className="list_inner">
                  <h3><img src="https://d1.awsstatic.com/training-and-certification/certification-badges/AWS-Certified-Solutions-Architect-Associate_badge.3419559c682629072f1eb968d59dea0741772c0f.png" alt="aws saa" /></h3>
                  <span>AWS Solutions Architect<br />Associate</span>
                </div>
              </li>
              <li>
                <div className="list_inner">
                  <h3><img src="https://pbs.twimg.com/profile_images/1486342270345711617/K01H_a5Z_400x400.png" alt="aws saa" /></h3>
                  <span>応用情報技術者<br />AP</span>
                </div>
              </li>
            </ul>
            {/* END COUNTER CONTENT */}
          </div>
          {/* END COUNTER */}
        </div>
        {/* END DESCRIPTION INNER */}
      </div>
    </>
  );
};

export default About;
